import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import {
  TextField, Button, Grid, Box, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText 
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth_APIs from '../../../utilities/api/auth_api';
import TinkerLabListAPIs from '../../../utilities/api/TinkersLab/TinkerLabListAPIs';
import expandleft from '../../../utilities/dummy_assets/Expand_left.png';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  position: Yup.string().required('Position is required'),
  other_position: Yup.string(),
  TL: Yup.string().required('Tinkerers\' Lab name is required'),
});

const buttonStyle = {
  display: 'flex',
  width: '25rem',
  padding: '1rem 1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '0.75rem',
  background: 'var(--Accent-Accent-00, #007BFF)',
};

const typographyStyle = {
  color: 'var(--Primary-white, #FFF)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.0165rem',
};

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const AdminRegistrationForm = () => {
  const navigate = useNavigate();
  const [collegeDomains, setCollegeDomains] = useState({});
  const [TLs, setTLs] = useState([]);
  const [isCollegeFieldDisabled, setCollegeFieldDisabled] = useState(false);
  const [allTLs, setAllTLs] = useState([]);

  useEffect(() => {
    const fetchCollegeDomains = async () => {
      try {
        const data = await TinkerLabListAPIs.TinkerLabsListGet();
        const domains = {};
        const allTinkerLabs = [];

        data.forEach((item) => {
          allTinkerLabs.push(item.name);
          item.accepted_ldaps.forEach((domain) => {
            if (!domains[domain]) {
              domains[domain] = [];
            }
            domains[domain].push(item.name);
          });
        });

        setCollegeDomains(domains);
        setAllTLs(allTinkerLabs);
      } catch (error) {
        toast.error('Error fetching college domains');
        console.error('Error fetching college domains:', error);
      }
    };

    fetchCollegeDomains();
  }, []);

  const checkIITEmail = (email) => {
    const domain = email.split('@')[1];
    return collegeDomains[`@${domain}`] || [];
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      position: '',
      other_position: '',
      TL: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Auth_APIs.register(values)
        .then((result) => {
          toast.success('You have registered successfully! You will receive login credentials once Admin accepts your request. You can log in with credentials here.', {
            onClose: () => navigate('/adminlogin')
          });
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const errorMessage = Object.values(error.response.data).flat().join(' ');
            toast.error(errorMessage);
          } else {
            toast.error('An unexpected error occurred.');
          }
        });
    },    
  });
  
  useEffect(() => {
    const colleges = checkIITEmail(formik.values.email);
    formik.setFieldValue('TL', colleges[0] || "");

    if (colleges.length > 1) {
      setCollegeFieldDisabled(false);
    } else if (colleges.length === 1) {
      setCollegeFieldDisabled(true);
    } else {
      setCollegeFieldDisabled(false);
    }
    setTLs(colleges.length > 0 ? colleges : allTLs);
  }, [formik.values.email, collegeDomains]);

  return (
    <Box
      sx={{
        width: '50rem',
        height: '30rem',
        mx: 'auto',
        p: 2,
        backgroundColor: 'transparent'
      }}
    >
      {/* ToastContainer should be high enough to capture all toasts */}
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} gap={15}>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Email Address
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Enter your email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Name
              </Typography>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Enter your name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Phone No.
              </Typography>
              <TextField
                fullWidth
                id="phone_number"
                name="phone_number"
                label="Enter phone no."
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Tinkerers' Laboratory Name
              </Typography>
              <FormControl
                fullWidth
                error={formik.touched.TL && Boolean(formik.errors.TL)}
                disabled={isCollegeFieldDisabled}
              >
                <InputLabel id="college-label">Select your Tinkerers' Lab name</InputLabel>
                <Select
                  labelId="college-label"
                  id="TL"
                  name="TL"
                  value={formik.values.TL}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TLs.map((college) => (
                    <MenuItem key={college} value={college}>
                      {college}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.TL && formik.errors.TL ? (
                  <FormHelperText>{formik.errors.TL}</FormHelperText>
                ) : null}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Position
              </Typography>
              <FormControl
                fullWidth
                error={formik.touched.position && Boolean(formik.errors.position)}
              >
                <InputLabel id="position-label">Select your position</InputLabel>
                <Select
                  labelId="position-label"
                  id="position"
                  name="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.keys(positionLabels).map((key) => (
                    <MenuItem key={key} value={key}>
                      {positionLabels[key]}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.position && formik.errors.position ? (
                  <FormHelperText>{formik.errors.position}</FormHelperText>
                ) : null}
              </FormControl>
              {formik.values.position === '4' && (
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Other Position
                  </Typography>
                  <TextField
                    fullWidth
                    id="other_position"
                    name="other_position"
                    label="Enter your position"
                    value={formik.values.other_position}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.other_position && Boolean(formik.errors.other_position)}
                    helperText={formik.touched.other_position && formik.errors.other_position}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'stretch', gap: '2rem', marginTop: '10px' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/login')}
            sx={{
              display: 'flex',
              width: '25rem',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '0.75rem',
              border: '1px solid var(--Primary-Primary03, #5C5C5C)',              
            }}
          >
            <Typography sx={typographyStyle && { color: 'black' }}>Cancel</Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={buttonStyle}
            disabled={formik.isSubmitting}
          >
            <Typography sx={typographyStyle}>Register</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            paddingTop: '.5rem'
          }}
        >
          <img src={expandleft} onClick={() => navigate('/login')}
            style={{
              cursor: 'pointer',
            }} />
          <Typography onClick={() => navigate('/login')} sx={typographyStyle && { fontSize: '1rem', letterSpacing: '-0.01375rem', color: 'var(--Accent-Accent-00, #007BFF)', cursor: 'pointer' }}>
            Back
          </Typography>
          <Typography sx={typographyStyle && { fontSize: '1rem', letterSpacing: '-0.01375rem', marginLeft: '30.2rem' }}>
            Already have an account? <span onClick={() => navigate('/login')} style={{ color: 'var(--Accent-Accent-01, #0062CC)', cursor: 'pointer' }}>Log in here</span>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default AdminRegistrationForm;
