import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateTL } from '../../redux/slices/userSlice'; // Adjust with the actual path to your userSlice file
import { Box, Button } from '@mui/material';
import NotificationCard from './notification'; // Adjust with the actual path to your NotificationCard component
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import CreateTL from './CreateTL'; // Adjust with the actual path to your CreateTL component
import { ImageBaseURL } from "../../utilities/api/axios";
import WebSocketService from '../../utilities/api/IMS/websocketService'; // Import your WebSocketService

const NavBar = () => {
    const [notifi, setNotifi] = useState(false);
    const [tinkerLabs, setTinkerLabs] = useState([]);
    const [openCreateTL, setOpenCreateTL] = useState(false);
    const [messageCount, setMessageCount] = useState(0); // New state for message count
    const { access, user } = useSelector((state) => state.user.user); // Adjusted access to user state
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

    useEffect(() => {
        const fetchTinkerLabs = async () => {
            try {
                const labs = await TinkerLabListAPIs.TinkerLabsListGet();
                setTinkerLabs(labs);
            } catch (error) {
                console.error('Error fetching Tinker Labs data:', error);
            }
        };

        fetchTinkerLabs();
    }, [openCreateTL]);

    useEffect(() => {
        // WebSocket connection for receiving messages
        const connectWebSocket = () => {
            WebSocketService.connect(access, user.username); // Use user.username for connection

            WebSocketService.onMessage((messageData) => {
                console.log("WebSocket message received:", messageData);
                // Increment the message count on receiving a new message
                setMessageCount(prevCount => prevCount + 1);
            });
        };

        connectWebSocket();

        return () => {
            WebSocketService.disconnect(); // Clean up on component unmount
        };
    }, [access, user.username]); // Add access and username as dependencies

    const handleNavigation = () => {
        navigate('/calendar'); // Replace '/calendar' with your desired path
    };

    const handleProfile = () => {
        navigate('/profile'); // Replace '/profile' with your desired path
    };

    const handleTLChange = (event) => {
        const selectedTLName = event.target.value;
        dispatch(updateTL({ TL: selectedTLName }));
    };

    const handleCreateTLOpen = () => {
        setOpenCreateTL(true);
    };

    const handleCreateTLClose = () => {
        setOpenCreateTL(false);
    };

    // Determine whether to show the profile icon
    const showProfileIcon = 
        (user.is_org_admin && user.TL_name === "Org-Admin") || 
        !user.is_org_admin;

    return (
        <>
            <AppBar position="static" color="inherit" elevation={1}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {user?.is_org_admin ? (
                            <Select
                                value={user.TL_name || ''}
                                onChange={handleTLChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select TL' }}
                            >
                                {tinkerLabs.map((lab) => (
                                    <MenuItem key={lab.id} value={lab.name}>
                                        {lab.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            user?.TL_name || ''
                        )}
                    </Typography>

                    {user?.TL_name === 'Org-Admin' && (
                        <Button variant="contained" color="primary" onClick={handleCreateTLOpen} sx={{ marginRight: '27px' }}>
                            Create TL
                        </Button>
                    )}

                    {user.TL_name !== "Org-Admin" && (
                      <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
                          <CalendarTodayIcon />
                      </IconButton>
                    )}

                    {/* Conditional rendering of profile icon */}
                    {showProfileIcon && (
                        <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
                            <Avatar alt="User Avatar" src={userAvatarUrl} />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            {notifi && <NotificationCard />}
            <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
        </>
    );
};

export default NavBar;
