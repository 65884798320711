import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './routes';
import ChatBar from './components/Messaging/Chatbar';
import { ToastContainer } from 'react-toastify';
import { useAxiosInterceptors } from './utilities/api/axios';
import Reportbutton from './components/reportButton/reportButton';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';

function App() {
  // useAxiosInterceptors();
  const { user } = useSelector((state) => state.user.user); // Adjusted access to user state

  const PostHogAppId = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

  posthog.init(PostHogAppId,
    {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always'
    }
  )

  if (user)
  {
    posthog.identify(
      user.id,
      { userID:user.id, email: user.email, name: user.username },
      {
        is_staff: user.is_staff,
        is_student: user.is_student,
        is_active: user.is_active,
        TL_Name:user.TL_name,
      },
    );
  }  

  return (
    <BrowserRouter>
      {/* <div className="App"> */}
        <AppRoutes />
        <Reportbutton/>
        {/* <ChatBar /> */}
        <ToastContainer />
      {/* </div> */}
    </BrowserRouter>
  );
}

export default App;


// import { BrowserRouter, Router } from 'react-router-dom';
// import './App.css';
// import { AppRoutes } from './routes';
// import { ToastContainer } from 'react-toastify';
// function App() {
//   return (
//     <BrowserRouter>
//       <AppRoutes/>
//     </BrowserRouter>
//   );
// }

// export default App;
