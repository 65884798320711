import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: { user: {}},
    reducers: {
        getUserSuccess: (state, action) => {
            state.user = action.payload;
            if (state.user.user && state.user.user.TL_name === 'Org-Admin') {
                state.user.user.is_org_admin = true;
            }
        },
        getUserFail: (state, action) => {
            state.error = action.payload;
        },
        updateUser: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        updateTokens: (state, action) => {
            // Ensure `refresh` and `access` are updated while keeping other user data intact
            if (state.user && state.user.user) {
              state.user.refresh = action.payload.refresh || state.user.refresh;
              state.user.access = action.payload.access || state.user.access;
            }
        },
        updateTL: (state, action) => {
            if (state.user && state.user.user) {
                state.user.user.TL_name = action.payload.TL;
            }
        },
        logoutUser: (state) => {
            state.user = {}; // Clear user state
        }
    },
});

export const { getUserSuccess, getUserFail, updateUser, updateTokens, updateTL, logoutUser } = userSlice.actions;
export default userSlice;
