class WebSocketService {
  constructor() {
    this.socket = null;
    this.messageCallback = null;
  }

  connect(accessToken, receiverUsername) {
    if (this.socket) {
      this.socket.close();
    }

    const url = `wss://sys.staging.mis.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;
    console.log(`Connecting to WebSocket at URL: ${url}`);

    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);

      // Ensure that the callback is only invoked once
      if (this.messageCallback) {
        this.messageCallback(data);
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed:', event);
      if (event.reason) {
        console.error('Reason for closure:', event.reason);
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  sendMessage(message, threadId) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const data = {
        message: message,
        thread_id: threadId,
        sender: this.senderUsername // Add this to track the sender explicitly
      };
      console.log('Sending WebSocket message:', JSON.stringify(data));
      this.socket.send(JSON.stringify(data));

      // Do not trigger the callback here to avoid duplication in the receiver's UI
    } else {
      console.error('WebSocket is not open. Cannot send message.');
    }
  }

  onMessage(callback) {
    this.messageCallback = callback;
  }
}

export default new WebSocketService();




/////////////////////////////////////////////////


// class WebSocketService {
//   constructor() {
//     this.socket = null;
//     this.messageCallback = null;
//   }

//   connect(accessToken, receiverUsername) {
//     if (this.socket) {
//       this.socket.close();
//     }

//     const url = `wss://sys.staging.mis.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;
//     console.log(`Connecting to WebSocket at URL: ${url}`);

//     this.socket = new WebSocket(url);

//     this.socket.onopen = () => {
//       console.log('WebSocket connected');
//     };

//     // this.socket.onmessage = (message) => {
//     //   console.log('WebSocket message received:', message);
//     //   this.onMessage(message);
//     // };

//     this.socket.onmessage = function(event) {
//       const data = JSON.parse(event.data);
      
//       console.log('WebSocket message received:', data);
//       // this.onMessage(data);
  
//       // if (data.notification) {
//       //     // Display notification or update UI to indicate a new messageconsole.log("New message received:", data.message);
//       //     // You can also trigger a notification sound, update the chat UI, etc.
//       // }
//   };

//     this.socket.onclose = (event) => {
//       console.log('WebSocket closed:', event);
//       if (event.reason) {
//         console.error('Reason for closure:', event.reason);
//       }
//       // Optionally, attempt to reconnect after a certain time
//       // setTimeout(() => this.connect(accessToken, receiverUsername), 5000);
//     };

//     this.socket.onerror = (error) => {
//       console.error('WebSocket error:', error);
//     };
//   }

//   disconnect() {
//     if (this.socket) {
//       this.socket.close();
//     }
//   }

//   onMessage(event) {
//     try {
//       const data = JSON.parse(event.data);
//       console.log('Parsed WebSocket data:', data);
//       if (this.messageCallback) {
//         this.messageCallback(data);
//       }
//     } catch (error) {
//       console.error('Error parsing WebSocket message:', error);
//     }
//   }

//   sendMessage(message, threadId) {
//     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
//       const data = JSON.stringify({
//         message: message,
//         thread_id: threadId,
//       });
//       console.log('Sending WebSocket message:', data);
//       this.socket.send(data);
//     } else {
//       console.error('WebSocket is not open. Cannot send message.');
//     }
//   }

//   onMessage(callback) {
//     this.messageCallback = callback;
//   }
// }

// export default new WebSocketService();



// // WebSocketService.js

// import { w3cwebsocket as W3CWebSocket } from 'websocket';

// class WebSocketService {
//   constructor() {
//     this.socket = null;
//     this.messageCallback = null;
//   }

//   connect(accessToken, receiverUsername) {
//     if (this.socket) {
//       this.socket.close();
//     }

//     console.log('WebSocketService accessToken', accessToken);

//     // Append the accessToken and receiverUsername as part of the URL
//     const url = `wss://staging.misadmin.mapit.ai/ws/chat/${receiverUsername}/?token=${accessToken}`;

//     this.socket = new W3CWebSocket(url);

//     this.socket.onopen = () => {
//       console.log('WebSocket connected');
//     };

//     this.socket.onmessage = (message) => {
//       this.onMessage(message);
//     };

//     this.socket.onclose = (event) => {
//       this.onClose(event);
//     };

//     this.socket.onerror = (error) => {
//       this.onError(error);
//     };
//   }

//   disconnect() {
//     if (this.socket) {
//       this.socket.close();
//     }
//   }

//   onMessage(event) {
//     try {
//       const data = JSON.parse(event.data);
//       if (this.messageCallback) {
//         this.messageCallback(data);
//       }
//     } catch (error) {
//       console.error('Error parsing WebSocket message:', error);
//     }
//   }

//   onClose(event) {
//     console.log('WebSocket closed:', event);
//   }

//   onError(error) {
//     console.log('WebSocket error:', error);
//   }

//   sendMessage(message, threadId) {
//     if (this.socket && this.socket.readyState === this.socket.OPEN) {
//       // Ensure the message is sent in the correct format
//       const data = JSON.stringify({
//         message: message,
//         thread_id: threadId,
//       });
//       this.socket.send(data);
//     } else {
//       console.error('WebSocket is not open');
//     }
//   }

//   onMessage(callback) {
//     this.messageCallback = callback;
//   }
// }

// export default new WebSocketService();
