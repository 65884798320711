import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Avatar, IconButton, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import AdminUserList from '../../../utilities/api/user/AdminUserList';
import { useSelector } from 'react-redux';
import { ImageBaseURL } from "../../../utilities/api/axios";

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const columns = [
  {
    field: 'name',
    headerName: 'User Name',
    flex: 1,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar src={`${ImageBaseURL}${params.row.image}`} alt={params.row.name} />
        <div>
          <div>{params.value}</div>
          <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
        </div>
      </Box>
    ),
  },
  { field: 'role', headerName: 'Role', flex: 0.5 },
  { 
    field: 'isAdmin', 
    headerName: 'Admin', 
    flex: 0.5,
    renderCell: (params) => (
      <Checkbox checked={params.value} disabled />
    ),
  },
  { 
    field: 'isStudent', 
    headerName: 'Student', 
    flex: 0.5,
    renderCell: (params) => (
      <Checkbox checked={params.value} disabled />
    ),
  },
  { field: 'group', headerName: 'Group Name', flex: 0.5 },
  {
    field: 'actions',
    headerName: 'Action',
    flex: 0.5,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton onClick={() => console.log('Delete', params.id)}>
          <DeleteIcon color="error" />
        </IconButton>
        <IconButton onClick={() => console.log('Edit', params.id)}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>
    ),
  },
];

const User = ({ searchQuery }) => { // Receive searchQuery as prop
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const user = useSelector(state => state.user.user);
  const currentUserCollegeName = user?.user?.TL_name;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AdminUserList.AllUserListGet();
        const formattedData = data
          .filter(user => user.TL_name === currentUserCollegeName)
          .map(user => ({
            id: user.id, // Use unique ID from API
            username: user.username, // For search purposes
            name: user.name || user.username, // Display name
            email: user.email,
            role: user.other_position || positionLabels[user.position] || 'N/A',
            group: user.TL_name,
            image: user.profile_pic,
            isAdmin: user.is_admin,
            isStudent: user.is_student,
          }));
        setRows(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user, currentUserCollegeName]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredRows(rows); // No filter applied
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredRows(
        rows.filter(
          row => 
            (row.name && row.name.toLowerCase().includes(lowerCaseQuery)) ||
            (row.username && row.username.toLowerCase().includes(lowerCaseQuery)) ||
            (row.email && row.email.toLowerCase().includes(lowerCaseQuery))
        )
      );
    }
  }, [searchQuery, rows]);

  return (
    <Box sx={{ height: 600, width: '100%' }}> {/* Increased height for better visibility */}
      <DataGrid
        rows={filteredRows} // Use filteredRows instead of rows
        columns={columns}
        pageSize={10} // Adjusted page size
        rowsPerPageOptions={[5, 10, 20]} // More pagination options
        checkboxSelection
      />
    </Box>
  );
};

export default User;
